import { useEffect } from 'react';
import API from '../api/Api';
import { CardDrugDosageCalculator } from '../components/CardDrugDosageCalculator';
import { useRWDContext } from '../contexts/RWDContext';

export const CalculatorPage = () => {
  const { track: trackRWD } = useRWDContext();
  useEffect(() => {
    API.trackEvent('drug_dosage_calculator');
    trackRWD({
      actionName: 'drug_calculator_page',
    });
  }, [trackRWD]);
  const handleRWDtrigger = (value: any) => {
    trackRWD({
      actionName: 'calculated_dosage_calculator',
      signalmentWeight: value.signalmentWeight,
      dosages: [
        {
          strength: value.dosageStrength,
          strengthUnit: value.dosageStrengthUnit,
          amount: value.dosageAmount,
          amountUnit: value.dosageAmountUnit,
          doseType: value.dosageDoseType,
        },
      ],
    });
  };
  return <CardDrugDosageCalculator handleRWDtrigger={handleRWDtrigger} />;
};
