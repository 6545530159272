import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  DiseaseInformation,
  DiseaseCauses,
  DiseaseSignalments,
  MidInformation,
  DiseaseSymptoms,
} from '../diagnoses/DiseaseInformation';
import { DiseaseInfo, TreatmentInfo } from '../../types/Diagnose.type';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { SymptomTypeCategory } from '../../types/Symptom.type';
import { arrangeSymptomsByCategory } from '../../utils/symptomsUtils';
import { DiseaseBreeds } from '../diagnoses/DiseaseBreeds';
import { DiseaseSearchType } from '../../types/diseasesAndTreatments/diseaseSearchType';
import { DiseaseTreatmentsDialog } from './DiseaseTreatmentsDialog';
import { DiseaseSourcesModal } from '../diagnoses/DiseaseSourcesModal';
import { useAnimalTypesContext } from '../../contexts/AnimalTypesContext';
import { useRWDContext } from '../../contexts/RWDContext';

const AccordionItem = ({
  diseaseInfo,
  symptomTypeCategories,
  getTreatmentInfo,
  treatmentInfo,
  breedId,
  animalTypeId,
  searchType,
  searchCause,
}: {
  diseaseInfo: DiseaseInfo;
  symptomTypeCategories: SymptomTypeCategory[];
  getTreatmentInfo: (animalTypeDiseaseId: number) => {};
  treatmentInfo: TreatmentInfo | undefined;
  breedId: number | undefined;
  animalTypeId: number;
  searchType: DiseaseSearchType;
  searchCause: number;
}) => {
  const categorySymptoms = arrangeSymptomsByCategory(
    symptomTypeCategories,
    diseaseInfo
  );
  const { animalTypes } = useAnimalTypesContext();
  const { track: trackRWD } = useRWDContext();
  const handleRWDtrigger = (value: any) => {
    const speciesInfo = animalTypes.find((species) => {
      return species.Id === animalTypeId;
    });
    const breed = diseaseInfo.DiseaseBreeds.find((breed) => {
      return breed.BreedId === breedId;
    });
    const cause = diseaseInfo.Disease_Causes.find((cause) => {
      return cause.CauseOfDisease.Id === searchCause;
    });
    if (value.actionName === 'opened_disease_diseases') {
      trackRWD({
        actionName: value.actionName,
        signalmentSpecies: speciesInfo?.Name,
        signalmentBreed: breed?.Breed.Name,
        diseases: [
          {
            name: diseaseInfo.Disease.Name,
            searchCause: cause?.CauseOfDisease.Name,
            searchMethod: DiseaseSearchType[searchType],
          },
        ],
      });
    } else if (value.actionName === 'show_treatments_diseases') {
      trackRWD({
        actionName: value.actionName,
        signalmentSpecies: speciesInfo?.Name,
        signalmentBreed: breed?.Breed.Name,
        diseases: [
          {
            name: diseaseInfo.Disease.Name,
            searchCause: cause?.CauseOfDisease.Name,
            searchMethod: DiseaseSearchType[searchType],
          },
        ],
      });
    } else if (value.actionName === 'selected_treatment_diseases') {
      trackRWD({
        actionName: value.actionName,
        signalmentSpecies: speciesInfo?.Name,
        signalmentBreed: breed?.Breed.Name,
        diseases: [
          {
            name: diseaseInfo.Disease.Name,
            searchCause: cause?.CauseOfDisease.Name,
            searchMethod: DiseaseSearchType[searchType],
          },
        ],
        treatments: [
          {
            name: value?.treatmentName,
            type: 'Drug',
          },
        ],
      });
    } else if (value.dosageCalculationMethod) {
      trackRWD({
        actionName: 'calculated_dosage_diseases',
        signalmentSpecies: speciesInfo?.Name,
        signalmentBreed: breed?.Breed.Name,
        signalmentWeight: value?.signalmentWeight,
        diseases: [
          {
            name: diseaseInfo.Disease.Name,
            searchCause: cause?.CauseOfDisease.Name,
            searchMethod: DiseaseSearchType[searchType],
          },
        ],
        treatments: [
          {
            name: value?.treatmentName,
            type: 'Drug',
          },
        ],
        dosages: [
          {
            strength: value?.dosageStrength,
            strengthUnit: value?.dosageStrengthUnit,
            amount: value?.dosageAmount,
            amountUnit: value?.dosageAmountUnit,
            doseType: value?.dosageDoseType,
          },
        ],
      });
    }
  };

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      onChange={(e, expanded) => {
        if (expanded) {
          handleRWDtrigger({ actionName: 'opened_disease_diseases' });
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="diseaseInfoPanel"
      >
        <Typography style={{ textAlign: 'left' }}>
          {diseaseInfo.Disease.Name}
          {diseaseInfo.Source_AnimalTypeDiseases && (
            <DiseaseSourcesModal
              sources={diseaseInfo.Source_AnimalTypeDiseases}
            />
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <DiseaseInformation diagnose={diseaseInfo} />
              <DiseaseCauses diagnose={diseaseInfo} />
              <DiseaseSignalments diagnose={diseaseInfo} />
              <DiseaseBreeds
                diagnose={diseaseInfo}
                highlightBreedId={breedId}
              />
              <MidInformation diagnose={diseaseInfo} />
              <DiseaseSymptoms
                categories={symptomTypeCategories}
                categorySymptoms={categorySymptoms}
                showAddButton={false}
              />
              <DiseaseTreatmentsDialog
                diseaseInfo={diseaseInfo}
                treatmentInfo={treatmentInfo}
                getTreatmentInfo={getTreatmentInfo}
                handleRWDtrigger={handleRWDtrigger}
              />
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const DiseasesAndTreatmentsAccordion = ({
  diseaseInfos,
  symptomTypeCategories,
  diseaseSearchValue,
  searchType,
  searchCause,
  searchBreed,
  getTreatmentInfo,
  treatmentInfos,
  animalTypeId,
}: {
  diseaseInfos: DiseaseInfo[];
  symptomTypeCategories: SymptomTypeCategory[];
  diseaseSearchValue: string;
  searchType: DiseaseSearchType;
  searchCause: number;
  searchBreed: number;
  getTreatmentInfo: (animalTypeDiseaseId: number) => {};
  treatmentInfos: TreatmentInfo[];
  animalTypeId: number;
}) => {
  let filteredDiseaseInfos: DiseaseInfo[] = [];
  if (searchType === DiseaseSearchType.name) {
    filteredDiseaseInfos = diseaseInfos.filter((diseaseInfo) => {
      if (diseaseSearchValue === '') return true;
      return diseaseInfo.Disease.Name.toLowerCase().includes(
        diseaseSearchValue.toLowerCase()
      );
    });
  } else if (searchType === DiseaseSearchType.breed) {
    filteredDiseaseInfos = diseaseInfos.filter((diseaseInfo) => {
      return diseaseInfo.DiseaseBreeds.find((breed) => {
        return breed.BreedId === searchBreed;
      });
    });
  } else {
    filteredDiseaseInfos = diseaseInfos.filter((diseaseInfo) => {
      return diseaseInfo.Disease_Causes.find((cause) => {
        return cause.CauseOfDisease.Id === searchCause;
      });
    });
  }

  return (
    <div>
      <hr />
      <Typography
        style={{ textAlign: 'left', margin: '1em 0', fontWeight: 'bold' }}
      >
        RESULTS
      </Typography>
      {filteredDiseaseInfos.length === 0 && (
        <Typography>
          Search combination didn&apos;t contain any diseases
        </Typography>
      )}
      {filteredDiseaseInfos.map((diseaseInfo) => {
        return (
          <AccordionItem
            key={diseaseInfo.Id}
            diseaseInfo={diseaseInfo}
            symptomTypeCategories={symptomTypeCategories}
            getTreatmentInfo={getTreatmentInfo}
            treatmentInfo={treatmentInfos.find((treatmentInfo) => {
              return treatmentInfo.Id === diseaseInfo.Id;
            })}
            breedId={searchBreed}
            animalTypeId={animalTypeId}
            searchType={searchType}
            searchCause={searchCause}
          />
        );
      })}
    </div>
  );
};
