import { Fragment, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  ROUTE_VISIT_TREATMENTS,
  ROUTE_VISIT_DIAGNOSIS,
  ROUTE_VISIT_DOSAGES,
  ROUTE_HOME,
} from '../../config/routes.config';
import { useDosagesPageDispatch } from '../../contexts/DosagesPageContext';
import { useDrugProductsDispatch } from '../../contexts/DrugProductsContext';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { useWorkHistory } from '../../hooks/useWorkHistory';
import { useIntegrationContext } from '../../contexts/IntegrationContext';
import { ButtonTo } from '../common/ButtonTo';
import { FloatingActionBar } from '../FloatingActionBar';
import { Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button } from '../Button';
import Loader from '../Loader';
import { useAuthUser } from '../../contexts/AuthUserContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { useRWDContext } from '../../contexts/RWDContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import {
  getRWDage,
  getRWDdiagnosesMethod3,
  getRWDsymptoms,
  getRWDtreatments,
} from '../../utils/rwdUtils';

const useStyles = makeStyles()((theme: Theme) => ({
  integrationButton: {
    marginRight: theme.spacing(2),
  },
}));

export const TreatmentsFloatingActionBar = () => {
  const { exportState } = useWorkHistory();
  const [showLoader, setShowLoader] = useState(false);
  const mobile = useMediaQuery('(max-width:600px)');
  const dispatchTreatments = useTreatmentsPageDispatch();
  const dispatchDosages = useDosagesPageDispatch();
  const dispatchDrugProducts = useDrugProductsDispatch();

  const { isTestPatient } = usePatientInfo();

  const { classes } = useStyles();

  const { signOut } = useAuthUser();

  const { integrationConnectionInfo, integrationInfo, integrationSource } =
    useIntegrationContext();

  let integrationCase = false;

  if (integrationConnectionInfo && integrationInfo && integrationSource) {
    integrationCase = true;
  }

  const handleGoToDiagnoses = () => {
    dispatchTreatments({ type: 'reset' });
    dispatchDosages({ type: 'reset' });
    dispatchDrugProducts({ type: 'resetAll' });
    if (!isTestPatient) {
      exportState();
    }
  };

  // variables for RWD
  const { track: trackRWD } = useRWDContext();
  const {
    selectedTreatmentsObj,
    selectedTreatments,
    selectedSubsequentDiseases,
    subsequentTreatmentInfos,
  } = useTreatmentsPage();
  const {
    diagnoses,
    selectedSymptoms,
    selectedSymptomsObj,
    selectedDiagnoses,
  } = useDiagnosesPage();
  const { symptoms } = useSymptoms();
  const patientInfo = usePatientInfo();
  // end of variables for RWD

  const handleSave = () => {
    dispatchDosages({ type: 'reset' });
    dispatchDrugProducts({ type: 'resetAll' });
    if (!isTestPatient) {
      exportState();
    }
    // Start of RWD functionality
    if (selectedTreatments.length > 0) {
      const arrayOfTreatments = getRWDtreatments(
        selectedTreatments,
        selectedTreatmentsObj,
        diagnoses
      );
      const arrayOfDiagnoses = getRWDdiagnosesMethod3(
        selectedDiagnoses,
        diagnoses,
        selectedSubsequentDiseases,
        subsequentTreatmentInfos
      );
      const arrayOfSymptoms = getRWDsymptoms(
        selectedSymptoms,
        symptoms,
        selectedSymptomsObj
      );
      const age = getRWDage(patientInfo.dateOfBirth);
      trackRWD({
        actionName: 'selected_treatments_diagnosis',
        treatments: arrayOfTreatments,
        diagnoses: arrayOfDiagnoses,
        symptoms: arrayOfSymptoms,
        signalmentSpecies:
          patientInfo.animalTypeName[0].toUpperCase() +
          patientInfo.animalTypeName.slice(1),
        signalmentBreed:
          patientInfo.breedName === '' ? undefined : patientInfo.breedName,
        signalmentWeight: +patientInfo.weight,
        signalmentGender: patientInfo.genderName,
        signalmentAge: age,
        signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
        signalmentPatientType: patientInfo.isTestPatient ? 'test' : 'clinical',
      });
    }
    // End of RWD functionality
  };

  const handleIntegrationCaseClose = async () => {
    try {
      setShowLoader(true);
      await exportState();
      signOut();
      window.close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FloatingActionBar>
      <Loader showLoader={showLoader} />
      <Grid item xs={12} container spacing={3}>
        {mobile ? (
          <Fragment>
            <Grid item xs={4}>
              <Typography align="left">
                {integrationCase ? (
                  <Button
                    onClick={handleIntegrationCaseClose}
                    title="Save & return"
                    color="primary"
                    variant="contained"
                    className={classes.integrationButton}
                  >
                    Save & return
                  </Button>
                ) : (
                  <ButtonTo
                    handleClick={handleSave}
                    secondary
                    title={isTestPatient ? 'Close' : 'Save & close'}
                    toRoute={ROUTE_HOME}
                    iconName="home"
                    mobile={mobile}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography align="right">
                <ButtonTo
                  currentRoute={ROUTE_VISIT_TREATMENTS}
                  handleClick={handleGoToDiagnoses}
                  secondary
                  title="Back to diagnoses"
                  toRoute={ROUTE_VISIT_DIAGNOSIS}
                  iconName="back"
                  mobile={mobile}
                />
                <ButtonTo
                  currentRoute={ROUTE_VISIT_TREATMENTS}
                  handleClick={handleSave}
                  title="Continue"
                  toRoute={ROUTE_VISIT_DOSAGES}
                  iconName="next"
                  mobile={mobile}
                />
              </Typography>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid item xs={12} sm={8}>
              <Typography align="left">
                <ButtonTo
                  currentRoute={ROUTE_VISIT_TREATMENTS}
                  handleClick={handleGoToDiagnoses}
                  secondary
                  title="Back to diagnoses"
                  toRoute={ROUTE_VISIT_DIAGNOSIS}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="right">
                {integrationCase ? (
                  <Button
                    onClick={handleIntegrationCaseClose}
                    title="Save & return"
                    color="primary"
                    variant="contained"
                    className={classes.integrationButton}
                  >
                    Save & return
                  </Button>
                ) : (
                  <ButtonTo
                    handleClick={handleSave}
                    secondary
                    title={isTestPatient ? 'Close' : 'Save & close'}
                    toRoute={ROUTE_HOME}
                  />
                )}
                <ButtonTo
                  currentRoute={ROUTE_VISIT_TREATMENTS}
                  handleClick={handleSave}
                  title="Continue"
                  toRoute={ROUTE_VISIT_DOSAGES}
                />
              </Typography>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </FloatingActionBar>
  );
};
