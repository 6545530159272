import { Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { TreatmentInfo } from '../../types/Diagnose.type';
import { Button } from '../Button';
import ConditionTooltip from '../diagnoses/ConditionTooltip';
import { SourcesDialog } from '../common/SourcesDialog';
import SubsequentDiseaseConfirmSwitch from './SubsequentDiseaseConfirmSwitch';
import { TreatmentsContent } from './TreatmentsContent';
import { useRWDContext } from '../../contexts/RWDContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import {
  getRWDage,
  getRWDdiagnosesMethod1,
  getRWDsymptoms,
} from '../../utils/rwdUtils';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
  },
  expanded: {},
}));

export default function SubsequentDisease({
  treatmentInfo,
  canSelect,
}: {
  treatmentInfo: TreatmentInfo;
  canSelect: boolean;
}) {
  const {
    subsequentTreatmentsObj,
    subsequentTreatmentInfos,
    selectedTreatmentsObj,
    selectedTreatments: selectedTreatmentIds,
    selectedSubsequentDiseases,
  } = useTreatmentsPage();
  const dispatchTreatments = useTreatmentsPageDispatch();

  const [sourceModalOpen, setSourceModalOpen] = useState(false);

  const toggleSourceModal = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent> | null,
    open: boolean
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setSourceModalOpen(open);
  };

  // RWD variables
  const { track: trackRWD } = useRWDContext();
  const {
    diagnoses,
    selectedDiagnoses,
    selectedSymptoms,
    selectedSymptomsObj,
  } = useDiagnosesPage();
  const { symptoms } = useSymptoms();
  const patientInfo = usePatientInfo();
  // End of RWD variables

  const handleSelect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const deselectTreatmentIds: number[] =
      subsequentTreatmentInfos && subsequentTreatmentsObj
        ? subsequentTreatmentInfos[
            subsequentTreatmentsObj[treatmentInfo.Id]
          ].Treatments.map((treatment) => treatment.Id)
        : [];

    const deselectedTreatmentIds: number[] = [];
    selectedTreatmentIds.forEach((treatmentId) => {
      if (
        selectedTreatmentsObj[treatmentId].TreatmentSet.Id === treatmentInfo.Id
      ) {
        deselectedTreatmentIds.push(treatmentId);
      }
    });

    window.dataLayer.push({
      event: 'selectedSubsequentDisease',
      diseaseId: treatmentInfo.Disease.Name,
    });
    dispatchTreatments({
      type: 'setSubsequentDiseaseSelected',
      checked: !canSelect,
      diseaseId: treatmentInfo.Id,
    });
    if (canSelect) {
      dispatchTreatments({
        type: 'deselectTreatments',
        deselectTreatmentIds,
      });
    }
    // Start of RWD functionality
    const arrayOfDiagnoses = getRWDdiagnosesMethod1(
      diagnoses,
      selectedDiagnoses,
      selectedSubsequentDiseases,
      subsequentTreatmentInfos,
      treatmentInfo.Disease.Name,
      canSelect
    );
    const arrayOfSymptoms = getRWDsymptoms(
      selectedSymptoms,
      symptoms,
      selectedSymptomsObj
    );
    const age = getRWDage(patientInfo.dateOfBirth);

    trackRWD({
      actionName: 'selected_diagnoses',
      diagnoses: arrayOfDiagnoses,
      symptoms: arrayOfSymptoms,
      signalmentSpecies:
        patientInfo.animalTypeName[0].toUpperCase() +
        patientInfo.animalTypeName.slice(1),
      signalmentBreed:
        patientInfo.breedName === '' ? undefined : patientInfo.breedName,
      signalmentWeight: +patientInfo.weight,
      signalmentGender: patientInfo.genderName,
      signalmentAge: age,
      signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
      signalmentPatientType: patientInfo.isTestPatient ? 'test' : 'clinical',
    });
    // end of RWD functionality
  };

  return (
    <Accordion square TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label={`Expand treatment ${treatmentInfo.Id}`}
        aria-controls={`treatment-diagnose-content-${treatmentInfo.Id}`}
        id={`treatment-diagnose-header-${treatmentInfo.Id}`}
      >
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} sm={10} container>
            <Grid item xs={12}>
              <Typography align="left">
                {treatmentInfo.Disease.Name} &nbsp;&nbsp;&nbsp;
                {treatmentInfo.Condition === 1 && <ConditionTooltip />}
              </Typography>
            </Grid>

            <Grid item xs={12} container>
              {!(treatmentInfo.Id in subsequentTreatmentsObj) && (
                <Grid item xs={2}>
                  <Typography
                    onClick={(event) => toggleSourceModal(event, true)}
                    style={{
                      color: 'gray',
                      fontSize: '12px',
                      letterSpacing: '0.09px',
                      marginBottom: '16px',
                    }}
                    align="left"
                  >
                    No sources
                  </Typography>
                </Grid>
              )}
              {treatmentInfo.Id in subsequentTreatmentsObj && (
                <Grid item xs={2}>
                  <Typography
                    onClick={(event) => toggleSourceModal(event, true)}
                    style={{
                      color: '#00aa79',
                      fontSize: '12px',
                      letterSpacing: '0.09px',
                      marginBottom: '16px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    align="left"
                  >
                    Sources
                  </Typography>
                  {sourceModalOpen && (
                    <SourcesDialog
                      isOpen={sourceModalOpen}
                      closeDialog={(event) => toggleSourceModal(event, false)}
                      sources={
                        subsequentTreatmentInfos![
                          subsequentTreatmentsObj[treatmentInfo.Id]
                        ].Source_AnimalTypeDiseases
                      }
                    />
                  )}
                </Grid>
              )}
            </Grid>
            {canSelect && (
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <SubsequentDiseaseConfirmSwitch
                    diagnoseId={treatmentInfo.Id}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={1} sm={2}>
            <Button
              title={canSelect ? 'Deselect' : 'Select'}
              variant="contained"
              onClick={handleSelect}
              style={{
                color: '#01AA70',
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                padding: '0.2em 0.5em',
              }}
            >
              {canSelect ? 'Deselect' : 'Select'}
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <TreatmentsContent treatmentInfo={treatmentInfo} canSelect={canSelect} />
    </Accordion>
  );
}
