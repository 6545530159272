import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import {
  DoseAlternative,
  DrugSubstanceInfo,
} from '../../types/DrugDosages.type';
import { AnimalType } from '../../types/AnimalType.type';
import { DrugDosageTable } from '../DrugDosageTable/TableComponents';
import {
  CalculatedDose,
  DefaultCalculatedDose,
  DefaultDoseInstruction,
} from '../../reducers/dosagesPage/dosages.page.state';
import { CalculatorDialog } from '../common/CalculatorDialog';
import { Sources } from '../common/Sources';

export const DrugSubstanceSearchResults = ({
  drugSubstanceInfo,
  searchAnimalType,
  searchDrugSubstanceValue,
  handleRWDtrigger,
}: {
  drugSubstanceInfo: DrugSubstanceInfo;
  searchAnimalType: AnimalType;
  searchDrugSubstanceValue: string;
  handleRWDtrigger: (value: any) => void;
}) => {
  const mobile = useMediaQuery('(max-width:600px)');

  const [calculatorDialogOpen, setCalculatorDialogOpen] =
    useState<boolean>(false);

  const [reloadCalculator, setReloadCalculator] = useState(false);

  const [chosenDoseAlternative, setChosenDoseAlternative] =
    useState<DoseAlternative>();

  const [calculatorState, setCalculatorState] = useState<{
    chosenDoseAlternative: DoseAlternative | null;
    currentCalculatedDoses: CalculatedDose[];
  }>({ chosenDoseAlternative: null, currentCalculatedDoses: [] });

  const setCalculatorDoseAlternative = (doseAlternative: DoseAlternative) => {
    const emptyCalculatedDosages = doseAlternative.DrugSubstanceDose_Phases.map(
      (dosePhase) => {
        return {
          ...DefaultCalculatedDose,
          dosePhaseId: dosePhase.Id,
          weight: '',
          doseInstruction: {
            ...DefaultDoseInstruction,
            doseTypeId: dosePhase.DoseType.Id,
          },
        };
      }
    );

    setCalculatorState({
      ...calculatorState,
      chosenDoseAlternative: doseAlternative,
      currentCalculatedDoses: emptyCalculatedDosages,
    });

    setCalculatorDialogOpen(true);

    setChosenDoseAlternative(doseAlternative);
  };

  const handleCloseDialog = () => {
    setCalculatorDialogOpen(false);
  };

  const handleCalculatedDose = (
    calculatedDose: CalculatedDose,
    index: number
  ) => {
    const newCalculatedDoses = [...calculatorState.currentCalculatedDoses];
    newCalculatedDoses.splice(index, 1, calculatedDose);

    setCalculatorState({
      ...calculatorState,
      currentCalculatedDoses: newCalculatedDoses,
    });
  };

  return (
    <Grid item xs={12} style={{ textAlign: 'left' }}>
      {drugSubstanceInfo.DrugSubstance_Doses.length > 0 && (
        <React.Fragment>
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              marginBottom: '0.5em',
            }}
          >
            {searchDrugSubstanceValue.toUpperCase()} DOSES FOR{' '}
            {searchAnimalType.Name.toUpperCase()}
          </Typography>
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {drugSubstanceInfo.DrugSubstance_Animals.Info}
          </Typography>
          <Sources
            sources={
              drugSubstanceInfo.DrugSubstance_Animals
                .Source_DrugSubstanceAnimals
            }
          ></Sources>
          <DrugDosageTable
            drugSubstanceInfo={drugSubstanceInfo}
            mobile={mobile}
            isDrugSubstances={true}
            setCalculatorDoseAlternative={setCalculatorDoseAlternative}
          />
        </React.Fragment>
      )}
      {drugSubstanceInfo.DrugSubstance_Doses.length === 0 && (
        <Typography style={{ marginBottom: '1em' }}>No results</Typography>
      )}
      {chosenDoseAlternative && (
        <CalculatorDialog
          open={calculatorDialogOpen}
          handleClose={handleCloseDialog}
          calculatorState={calculatorState}
          handleCalculatedDose={handleCalculatedDose}
          reload={reloadCalculator}
          setReload={setReloadCalculator}
          mobile={mobile}
          treatmentName={searchDrugSubstanceValue}
          animalTypeName={searchAnimalType.Name}
          drugSubstanceId={
            drugSubstanceInfo.DrugSubstance_Animals.DrugSubstanceId
          }
          doseAlternative={chosenDoseAlternative}
          handleRWDtrigger={handleRWDtrigger}
          drugSubstanceInfo={drugSubstanceInfo}
        />
      )}
    </Grid>
  );
};
