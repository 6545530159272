import { Card } from './common/Card';
import React, { useState, Fragment } from 'react';
import { SpeciesSelect } from './common/SpeciesSelect';
import { ANIMAL_TYPE_ID_UNDEFINED } from '../types/AnimalType.type';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DiseasesAndTreatmentsAccordion } from './diseases-and-treatments/DiseasesAndTreatmentsAccordion';
import {
  CauseOfDisease,
  DiseaseInfo,
  TreatmentInfo,
} from '../types/Diagnose.type';
import { Symptom, SymptomTypeCategory } from '../types/Symptom.type';
import Loader from './Loader';
import Grid from '@mui/material/Grid';
import { DiseaseSearchType } from '../types/diseasesAndTreatments/diseaseSearchType';
import { Select } from '@mui/material';
import { Breed } from '../types/Breed.type';
import { BreedsComboBox } from './add-new-patient/BreedsComboBox';

const useStyles = makeStyles()(() => ({
  root: {},
  topText: { textAlign: 'left' },
  bottomText: { textAlign: 'left', marginBottom: '1em' },
}));

export const DiseasesAndTreatments = ({
  diseaseInfos,
  getDiseaseInfos,
  symptomTypeCategories,
  isDiseaseInfoLoading,
  setIsDiseaseInfoLoading,
  symptoms,
  causeOfDiseases,
  getBreedsByAnimalType,
  animalTypeBreeds,
  isLoadingBreeds,
  getTreatmentInfo,
  treatmentInfos,
  isTreatmentInfoLoading,
}: {
  diseaseInfos: DiseaseInfo[];
  getDiseaseInfos: (animalTypeId: string) => {};
  symptomTypeCategories: SymptomTypeCategory[];
  isDiseaseInfoLoading: boolean;
  setIsDiseaseInfoLoading: any;
  symptoms: Symptom[];
  causeOfDiseases: CauseOfDisease[];
  getBreedsByAnimalType: (animalTypeId: number) => void;
  animalTypeBreeds: Breed[];
  isLoadingBreeds: boolean;
  getTreatmentInfo: (animalTypeDiseaseId: number) => {};
  treatmentInfos: TreatmentInfo[];
  isTreatmentInfoLoading: boolean;
}) => {
  const { classes } = useStyles();

  const [state, setState] = useState<{
    AnimalTypeId: number;
    diseaseSearchValueUser: string;
    diseaseSearchValue: string;
    searchType: DiseaseSearchType;
    searchCause: number;
    searchBreed: number;
  }>({
    AnimalTypeId: ANIMAL_TYPE_ID_UNDEFINED,
    diseaseSearchValueUser: '',
    diseaseSearchValue: '',
    searchType: DiseaseSearchType.name,
    searchCause: 0,
    searchBreed: 0,
  });
  const [breedBoxInputValue, setBreedBoxInputValue] = useState('');

  const handleBreedBoxInputValueChange = (value: string) => {
    setBreedBoxInputValue(value);
  };

  const handleBreedInputChange = (value: number) => {
    setState({ ...state, searchBreed: value });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (target.name === 'AnimalTypeId') {
      setIsDiseaseInfoLoading(true);
      getDiseaseInfos(value);
      setState({ ...state, [name]: value });
      getBreedsByAnimalType(value);
    } else if (target.name === 'diseaseSearchValueUser') {
      if (value.length > 1) {
        // Execute actual search only when there are at least 2 letters (performance)
        setState({
          ...state,
          diseaseSearchValueUser: value,
          diseaseSearchValue: value,
        });
      } else {
        setState({
          ...state,
          diseaseSearchValueUser: value,
        });
      }
    } else if (target.name === 'searchType') {
      setState({
        ...state,
        [name]: Number(value),
        diseaseSearchValue: '',
        diseaseSearchValueUser: '',
      });
    } else if (target.name === 'searchCause') {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  return (
    <Card title="SEARCH DISEASES" pageView="drawerView">
      <Loader showLoader={isLoadingBreeds} />
      <Grid container>
        <Typography className={classes.topText} gutterBottom>
          Choose the animal species first. Then you can search diseases by
          typing part of the disease name in the text field and choose search
        </Typography>
        <Typography className={classes.bottomText}>
          By clicking a disease open from the list you can see more information
          about the disease and it&apos;s recommended treatments
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SpeciesSelect
            error={false}
            species={state.AnimalTypeId}
            handleChange={handleInputChange}
          />
        </Grid>
      </Grid>
      {state.AnimalTypeId !== ANIMAL_TYPE_ID_UNDEFINED && (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant="standard" component="fieldset" fullWidth>
                <FormLabel component="legend" style={{ textAlign: 'left' }}>
                  Search by
                </FormLabel>
                <RadioGroup
                  aria-label="searchType"
                  name="searchType"
                  value={state.searchType}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value={DiseaseSearchType.name}
                    control={<Radio />}
                    label="Name"
                  />
                  <FormControlLabel
                    value={DiseaseSearchType.breed}
                    control={<Radio />}
                    label="Breed"
                  />
                  <FormControlLabel
                    value={DiseaseSearchType.cause}
                    control={<Radio />}
                    label="Cause"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {state.searchType === DiseaseSearchType.name && (
                <TextField
                  name="diseaseSearchValueUser"
                  label="Type to search (min. 2 characters)"
                  fullWidth
                  variant="outlined"
                  onChange={handleInputChange}
                  value={state.diseaseSearchValueUser}
                />
              )}
              {state.searchType === DiseaseSearchType.cause && (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Disease Cause
                  </InputLabel>
                  <Select
                    variant="standard"
                    name="searchCause"
                    labelId="demo-simple-select-outlined-label"
                    value={state.searchCause}
                    onChange={handleInputChange}
                    label="Disease Cause"
                  >
                    <MenuItem value={0}>
                      <em>Choose Cause</em>
                    </MenuItem>
                    {causeOfDiseases.map((causeOfDisease) => {
                      return (
                        <MenuItem
                          key={causeOfDisease.Id}
                          value={causeOfDisease.Id}
                        >
                          {causeOfDisease.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {state.searchType === DiseaseSearchType.breed && (
                <FormControl variant="outlined" fullWidth>
                  <BreedsComboBox
                    value={state.searchBreed}
                    handleChange={handleBreedInputChange}
                    inputValue={breedBoxInputValue}
                    handleOnInputValueChange={handleBreedBoxInputValueChange}
                    breedsInitData={animalTypeBreeds}
                    disabled={animalTypeBreeds.length === 0}
                    helperText={null}
                  ></BreedsComboBox>
                </FormControl>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Loader
                showLoader={isDiseaseInfoLoading || isTreatmentInfoLoading}
              />
              {diseaseInfos.length > 0 &&
                symptomTypeCategories.length > 0 &&
                symptoms.length > 0 &&
                !isDiseaseInfoLoading && (
                  <DiseasesAndTreatmentsAccordion
                    diseaseInfos={diseaseInfos}
                    symptomTypeCategories={symptomTypeCategories}
                    diseaseSearchValue={state.diseaseSearchValue}
                    searchType={state.searchType}
                    searchCause={state.searchCause}
                    searchBreed={state.searchBreed}
                    getTreatmentInfo={getTreatmentInfo}
                    treatmentInfos={treatmentInfos}
                    animalTypeId={state.AnimalTypeId}
                  />
                )}
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Card>
  );
};
