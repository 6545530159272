import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { Button } from '../Button';
import { Card } from '../common/Card';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import { AddedSymptoms } from './AddedSymptoms';
import Typography from '@mui/material/Typography';
import RequiredTooltip from './RequiredTooltip';
import { useGeographicalAreas } from '../../contexts/GeographicalAreasContext';
import { DiagnosesFilterOptions } from '../../pages/DiagnosesPage';
import { useSymptoms } from '../../contexts/SymptomsContext';
import API from '../../api/Api';
import { useRWDContext } from '../../contexts/RWDContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { getRWDage, getRWDsymptoms } from '../../utils/rwdUtils';

const useStyles = makeStyles()((theme) => ({
  actionButton: {
    minWidth: 150,
    alignSelf: 'flex-end',
    marginLeft: 20,
  },
}));

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const CardAddedSymptoms = ({
  setDiagnosesFilterOptions,
}: {
  setDiagnosesFilterOptions: (value: DiagnosesFilterOptions) => void;
}) => {
  const { geographicalAreas } = useGeographicalAreas();
  const { selectedSymptomsObj, selectedSymptoms } = useDiagnosesPage();
  const { symptoms } = useSymptoms();
  const dispatch = useDiagnosesPageDispatch();

  const { classes } = useStyles();

  const mobile = useMediaQuery('(max-width:600px)');

  const [gaState, setGAState] = React.useState<number[]>([]);

  const [youngAnimals, setYoungAnimals] = React.useState(false);
  const [geriatricAnimals, setGeriatricAnimals] = React.useState(false);

  // Additional RWD variables
  const { track: trackRWD } = useRWDContext();
  const patientInfo = usePatientInfo();
  // End of additional RWD variables

  const handleDifferentialSearch = () => {
    const ga: Array<{ GeographicalAreaId: number }> = gaState.map((Id) => ({
      GeographicalAreaId: Id,
    }));
    window.dataLayer.push({
      event: 'addedSymptoms',
      symptomsTotal: selectedSymptoms.length,
    });

    (async () => {
      for (const symptomId of selectedSymptoms) {
        const symptom = symptoms.find(({ Id }) => Id === symptomId);
        if (!symptom) return;
        const main = symptom.MainSymptomId
          ? symptoms.find(({ Id }) => Id === symptom.MainSymptomId)
          : null;
        const symptomName = main
          ? `${main.Name}, ${symptom.Name}`
          : symptom.Name;
        API.trackEvent('added_symptom', symptomName);
        await new Promise((resolve) => setTimeout(resolve, 20000));
      }
    })();
    // Start of RWD functionality
    const arrayOfSymptoms = getRWDsymptoms(
      selectedSymptoms,
      symptoms,
      selectedSymptomsObj
    );
    // format all geographical areas ready to be sent
    // the array of geographcial areas (ga) is kept undefined if there is no ga's entered
    let arrayOfGA = undefined;
    if (ga.length > 0) {
      arrayOfGA = [];
      for (const gaId of ga) {
        const gaInfo = geographicalAreas.find((geoa) => {
          return geoa.Id === gaId.GeographicalAreaId;
        });
        arrayOfGA.push({ name: gaInfo?.Name });
      }
    }
    const age = getRWDage(patientInfo.dateOfBirth);
    trackRWD({
      actionName: 'searched_differentials',
      symptoms: arrayOfSymptoms,
      geographicalAreas: arrayOfGA,
      signalmentSpecies:
        patientInfo.animalTypeName[0].toUpperCase() +
        patientInfo.animalTypeName.slice(1),
      signalmentBreed:
        patientInfo.breedName === '' ? undefined : patientInfo.breedName,
      signalmentWeight: +patientInfo.weight,
      signalmentGender: patientInfo.genderName,
      signalmentAge: age,
      signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
      signalmentPatientType: patientInfo.isTestPatient ? 'test' : 'clinical',
    });
    // End of RWD functionality

    setDiagnosesFilterOptions({
      typicallyYoungAnimals: youngAnimals,
      typicallyGeriatricAnimals: geriatricAnimals,
      relevantGeographicalAreas: ga,
    });
  };

  const handleResetSymptoms = () => {
    dispatch({ type: 'resetSelectedSymptoms' });
  };

  const handleGAChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    Id: number
  ) => {
    event.stopPropagation();
    const newGAState = [...gaState];
    const index = gaState.indexOf(Id);
    if (checked && index === -1) {
      newGAState.push(Id);
    } else if (!checked && index > -1) {
      newGAState.splice(index, 1);
    }
    setGAState(newGAState);
  };

  const addedAmount = Object.keys(selectedSymptomsObj).length;

  useEffect(() => {
    if (addedAmount === 0) {
      if (gaState.length > 0) {
        setGAState([]);
      }
      if (youngAnimals) {
        setYoungAnimals(false);
      }
      if (geriatricAnimals) {
        setGeriatricAnimals(false);
      }
    }
  }, [addedAmount, gaState.length, geriatricAnimals, youngAnimals]);

  if (addedAmount === 0) {
    return (
      <Card
        title="ADDED SYMPTOMS"
        pageView="drawerView"
        maxWidth
        withoutMarginBottom
      >
        <Typography align="left">No symptoms are added</Typography>
      </Card>
    );
  }

  return (
    <Card
      title="ADDED SYMPTOMS"
      pageView="drawerView"
      maxWidth
      withoutMarginBottom
    >
      <Grid container spacing={3}>
        <Grid item xs={12} container>
          <Grid item xs={12} sm={8} container>
            <Grid item xs={12} container>
              <Grid item xs={9}></Grid>
              <Grid
                item
                xs={2}
                style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
              >
                Required
                {!mobile && <RequiredTooltip />}
              </Grid>
              {!mobile && (
                <Grid
                  item
                  xs={1}
                  style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                >
                  Remove
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} container>
          <Grid item xs={12}>
            <AddedSymptoms />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}
        >
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
              FILTER
            </Typography>
            <Typography
              align="left"
              style={{ paddingTop: '0.5em', fontWeight: 'bold' }}
            >
              Relevant to geographical areas
            </Typography>
          </Grid>
          <Grid item xs={12} container direction="row">
            {geographicalAreas.map((ga) => {
              return (
                <Grid
                  item
                  key={ga.Name}
                  xs={12}
                  sm={6}
                  style={{ textAlign: 'left' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={gaState.indexOf(ga.Id) > -1}
                        onChange={(event, checked) =>
                          handleGAChange(event, checked, ga.Id)
                        }
                        name={ga.Name}
                        color="primary"
                      />
                    }
                    label={ga.Name}
                  />
                </Grid>
              );
            })}
          </Grid>
          {/* 
          //Hide typically young and geriatric disesaese filters for now.
          <Grid item xs={12}>
            <Typography
              align="left"
              style={{ paddingTop: '0.5em', fontWeight: 'bold' }}
            >
              Show diseases that occur
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={youngAnimals}
                    onChange={(e) => {
                      setYoungAnimals(e.target.checked);
                    }}
                    name="young animals"
                    color="primary"
                  />
                }
                label="Typically on young animals"
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={geriatricAnimals}
                    onChange={(e) => {
                      setGeriatricAnimals(e.target.checked);
                    }}
                    name="geriatric animals"
                    color="primary"
                  />
                }
                label="Typically on geriatric animals"
              />
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} style={{ textAlign: 'right' }}>
          <Button
            title="Clean"
            variant="contained"
            className={classes.actionButton}
            onClick={handleResetSymptoms}
            style={{
              color: '#01AA70',
              backgroundColor: 'white',
              border: '1px solid rgba(0, 0, 0, 0.2)',
              paddingBottom: '5px',
            }}
          >
            Clear all
          </Button>
          <Button
            title="Search Differentials"
            color="primary"
            variant="contained"
            className={classes.actionButton}
            onClick={handleDifferentialSearch}
          >
            SEARCH DIFFERENTIALS
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
