import React, { createContext, useContext, useMemo, useState } from 'react';
import { useVeterinarian } from './VeterinarianContext';
import APILayer from '../api/ApiLayer';

type RWDContextType = {
  track: (event: { actionName: string; [key: string]: any }) => void;
};

const RWDContext = createContext<RWDContextType>({
  track: () => {},
});

export const RWDContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const veterinarianState = useVeterinarian();
  const [session_id] = useState(Date.now());
  const track = useMemo(() => {
    if (!veterinarianState.veterinarian) return () => {};
    return (event: { actionName: string; [key: string]: any }) => {
      const account = {
        email: veterinarianState.veterinarian?.Email,
        personTypeId: veterinarianState.veterinarian?.PersonTypeId,
        countryId: veterinarianState.veterinarian?.CountryId,
      };
      APILayer.postRequest('/rwd', {
        ...event,
        account,
        session_id,
      });
      console.log(account, session_id, event);
    };
  }, [veterinarianState, session_id]);
  return (
    <RWDContext.Provider value={{ track }}>{children}</RWDContext.Provider>
  );
};

export const useRWDContext = () => {
  return useContext(RWDContext);
};
